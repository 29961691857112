import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Agency } from 'src/app/shared/models';

export const AgencyActions = createActionGroup({
  source: 'Agency',
  events: {
    'Load Agencies': emptyProps(),
    'Load Agencies Success': props<{ agencies: Agency[] }>(),
    'Load Agencies Failure': props<{ error: any }>(),
    'Load Agency': props<{ agencyId: string }>(),
    'Load Agency Success': props<{ agency: Agency }>(),
    'Load Agency Failure': props<{ error: any }>(),
    'Add Agency': props<{ agency: Agency }>(),
    'Add Agency Success': props<{ agency: Agency }>(),
    'Add Agency Failure': props<{ error: any }>(),
    'Remove Agency': props<{ agencyId: string }>(),
    'Remove Agency Success': props<{ agencyId: string }>(),
    'Remove Agency Failure': props<{ error: any }>(),
    'Update Agency': props<{
      agencyId: string;
      agency: Partial<Agency>;
    }>(),
    'Update Agency Success': props<{
      agencyId: string;
      agency: Partial<Agency>;
    }>(),
    'Update Agency Failure': props<{ error: any }>(),
  },
});
