/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface Note {
  agencyId: string;
  leadId: string;
  noteContent: string;
  noteHistory: NoteHistoryItem[];
  isPublic: boolean;
  shareableAgency: string[];
  createdDate: string;
  updatedDate: string;
}

export interface NoteHistoryItem {
  id: string;
  type: string;
  agentId: string;
  note: string;
  actions: string[];
  createdDate: string;
  updatedDate: string;
}

export interface NoteHistoryItemPayload {
  id: string;
  type: string;
  agentId: string;
  note: string;
  action: string;
  createdDate: string;
  updatedDate: string;
}

function createBaseNote(): Note {
  return {
    agencyId: "",
    leadId: "",
    noteContent: "",
    noteHistory: [],
    isPublic: false,
    shareableAgency: [],
    createdDate: "",
    updatedDate: "",
  };
}

export const Note = {
  encode(message: Note, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.agencyId !== "") {
      writer.uint32(10).string(message.agencyId);
    }
    if (message.leadId !== "") {
      writer.uint32(18).string(message.leadId);
    }
    if (message.noteContent !== "") {
      writer.uint32(26).string(message.noteContent);
    }
    for (const v of message.noteHistory) {
      NoteHistoryItem.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.isPublic === true) {
      writer.uint32(40).bool(message.isPublic);
    }
    for (const v of message.shareableAgency) {
      writer.uint32(50).string(v!);
    }
    if (message.createdDate !== "") {
      writer.uint32(58).string(message.createdDate);
    }
    if (message.updatedDate !== "") {
      writer.uint32(66).string(message.updatedDate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Note {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.agencyId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.leadId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.noteContent = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.noteHistory.push(NoteHistoryItem.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isPublic = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.shareableAgency.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdDate = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.updatedDate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Note {
    return {
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : "",
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      noteContent: isSet(object.noteContent) ? String(object.noteContent) : "",
      noteHistory: Array.isArray(object?.noteHistory)
        ? object.noteHistory.map((e: any) => NoteHistoryItem.fromJSON(e))
        : [],
      isPublic: isSet(object.isPublic) ? Boolean(object.isPublic) : false,
      shareableAgency: Array.isArray(object?.shareableAgency) ? object.shareableAgency.map((e: any) => String(e)) : [],
      createdDate: isSet(object.createdDate) ? String(object.createdDate) : "",
      updatedDate: isSet(object.updatedDate) ? String(object.updatedDate) : "",
    };
  },

  toJSON(message: Note): unknown {
    const obj: any = {};
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    message.leadId !== undefined && (obj.leadId = message.leadId);
    message.noteContent !== undefined && (obj.noteContent = message.noteContent);
    if (message.noteHistory) {
      obj.noteHistory = message.noteHistory.map((e) => e ? NoteHistoryItem.toJSON(e) : undefined);
    } else {
      obj.noteHistory = [];
    }
    message.isPublic !== undefined && (obj.isPublic = message.isPublic);
    if (message.shareableAgency) {
      obj.shareableAgency = message.shareableAgency.map((e) => e);
    } else {
      obj.shareableAgency = [];
    }
    message.createdDate !== undefined && (obj.createdDate = message.createdDate);
    message.updatedDate !== undefined && (obj.updatedDate = message.updatedDate);
    return obj;
  },

  create<I extends Exact<DeepPartial<Note>, I>>(base?: I): Note {
    return Note.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Note>, I>>(object: I): Note {
    const message = createBaseNote();
    message.agencyId = object.agencyId ?? "";
    message.leadId = object.leadId ?? "";
    message.noteContent = object.noteContent ?? "";
    message.noteHistory = object.noteHistory?.map((e) => NoteHistoryItem.fromPartial(e)) || [];
    message.isPublic = object.isPublic ?? false;
    message.shareableAgency = object.shareableAgency?.map((e) => e) || [];
    message.createdDate = object.createdDate ?? "";
    message.updatedDate = object.updatedDate ?? "";
    return message;
  },
};

function createBaseNoteHistoryItem(): NoteHistoryItem {
  return { id: "", type: "", agentId: "", note: "", actions: [], createdDate: "", updatedDate: "" };
}

export const NoteHistoryItem = {
  encode(message: NoteHistoryItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    if (message.agentId !== "") {
      writer.uint32(26).string(message.agentId);
    }
    if (message.note !== "") {
      writer.uint32(34).string(message.note);
    }
    for (const v of message.actions) {
      writer.uint32(42).string(v!);
    }
    if (message.createdDate !== "") {
      writer.uint32(50).string(message.createdDate);
    }
    if (message.updatedDate !== "") {
      writer.uint32(58).string(message.updatedDate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NoteHistoryItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNoteHistoryItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.agentId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.note = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.actions.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdDate = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.updatedDate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NoteHistoryItem {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      type: isSet(object.type) ? String(object.type) : "",
      agentId: isSet(object.agentId) ? String(object.agentId) : "",
      note: isSet(object.note) ? String(object.note) : "",
      actions: Array.isArray(object?.actions) ? object.actions.map((e: any) => String(e)) : [],
      createdDate: isSet(object.createdDate) ? String(object.createdDate) : "",
      updatedDate: isSet(object.updatedDate) ? String(object.updatedDate) : "",
    };
  },

  toJSON(message: NoteHistoryItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined && (obj.type = message.type);
    message.agentId !== undefined && (obj.agentId = message.agentId);
    message.note !== undefined && (obj.note = message.note);
    if (message.actions) {
      obj.actions = message.actions.map((e) => e);
    } else {
      obj.actions = [];
    }
    message.createdDate !== undefined && (obj.createdDate = message.createdDate);
    message.updatedDate !== undefined && (obj.updatedDate = message.updatedDate);
    return obj;
  },

  create<I extends Exact<DeepPartial<NoteHistoryItem>, I>>(base?: I): NoteHistoryItem {
    return NoteHistoryItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NoteHistoryItem>, I>>(object: I): NoteHistoryItem {
    const message = createBaseNoteHistoryItem();
    message.id = object.id ?? "";
    message.type = object.type ?? "";
    message.agentId = object.agentId ?? "";
    message.note = object.note ?? "";
    message.actions = object.actions?.map((e) => e) || [];
    message.createdDate = object.createdDate ?? "";
    message.updatedDate = object.updatedDate ?? "";
    return message;
  },
};

function createBaseNoteHistoryItemPayload(): NoteHistoryItemPayload {
  return { id: "", type: "", agentId: "", note: "", action: "", createdDate: "", updatedDate: "" };
}

export const NoteHistoryItemPayload = {
  encode(message: NoteHistoryItemPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    if (message.agentId !== "") {
      writer.uint32(26).string(message.agentId);
    }
    if (message.note !== "") {
      writer.uint32(34).string(message.note);
    }
    if (message.action !== "") {
      writer.uint32(42).string(message.action);
    }
    if (message.createdDate !== "") {
      writer.uint32(50).string(message.createdDate);
    }
    if (message.updatedDate !== "") {
      writer.uint32(58).string(message.updatedDate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NoteHistoryItemPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNoteHistoryItemPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.agentId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.note = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.action = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdDate = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.updatedDate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NoteHistoryItemPayload {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      type: isSet(object.type) ? String(object.type) : "",
      agentId: isSet(object.agentId) ? String(object.agentId) : "",
      note: isSet(object.note) ? String(object.note) : "",
      action: isSet(object.action) ? String(object.action) : "",
      createdDate: isSet(object.createdDate) ? String(object.createdDate) : "",
      updatedDate: isSet(object.updatedDate) ? String(object.updatedDate) : "",
    };
  },

  toJSON(message: NoteHistoryItemPayload): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined && (obj.type = message.type);
    message.agentId !== undefined && (obj.agentId = message.agentId);
    message.note !== undefined && (obj.note = message.note);
    message.action !== undefined && (obj.action = message.action);
    message.createdDate !== undefined && (obj.createdDate = message.createdDate);
    message.updatedDate !== undefined && (obj.updatedDate = message.updatedDate);
    return obj;
  },

  create<I extends Exact<DeepPartial<NoteHistoryItemPayload>, I>>(base?: I): NoteHistoryItemPayload {
    return NoteHistoryItemPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NoteHistoryItemPayload>, I>>(object: I): NoteHistoryItemPayload {
    const message = createBaseNoteHistoryItemPayload();
    message.id = object.id ?? "";
    message.type = object.type ?? "";
    message.agentId = object.agentId ?? "";
    message.note = object.note ?? "";
    message.action = object.action ?? "";
    message.createdDate = object.createdDate ?? "";
    message.updatedDate = object.updatedDate ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
