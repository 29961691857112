import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Agent } from 'src/app/shared/models';

export const AgentActions = createActionGroup({
  source: 'Agent',
  events: {
    'Load Agents': emptyProps(),
    'Load Agents Success': props<{ agents: Agent[] }>(),
    'Load Agents Failure': props<{ error: Error }>(),
    'Load Agent': props<{ agentId: string }>(),
    'Load Agent Success': props<{ agent: Agent }>(),
    'Load Agent Failure': props<{ error: Error }>(),
    'Add Agent': props<{ agent: Agent }>(),
    'Add Agent Success': props<{ agent: Agent }>(),
    'Add Agent Failure': props<{ error: Error }>(),
    'Remove Agent': props<{ agentId: string }>(),
    'Remove Agent Success': props<{ agentId: string }>(),
    'Remove Agent Failure': props<{ error: Error }>(),
    'Update Agent': props<{
      agentId: string;
      agent: Agent;
    }>(),
    'Update Agent Success': props<{
      agentId: string;
      agent: Agent;
    }>(),
    'Update Agent Failure': props<{ error: Error }>(),
  },
});
