"use strict";

module.exports = BufferReader;

// extends Reader
var Reader = require("./reader");
(BufferReader.prototype = Object.create(Reader.prototype)).constructor = BufferReader;
var util = require("./util/minimal");

/**
 * Constructs a new buffer reader instance.
 * @classdesc Wire format reader using node buffers.
 * @extends Reader
 * @constructor
 * @param {Buffer} buffer Buffer to read from
 */
function BufferReader(buffer) {
  Reader.call(this, buffer);

  /**
   * Read buffer.
   * @name BufferReader#buf
   * @type {Buffer}
   */
}
BufferReader._configure = function () {
  /* istanbul ignore else */
  if (util.Buffer) BufferReader.prototype._slice = util.Buffer.prototype.slice;
};

/**
 * @override
 */
BufferReader.prototype.string = function read_string_buffer() {
  var len = this.uint32(); // modifies pos
  return this.buf.utf8Slice ? this.buf.utf8Slice(this.pos, this.pos = Math.min(this.pos + len, this.len)) : this.buf.toString("utf-8", this.pos, this.pos = Math.min(this.pos + len, this.len));
};

/**
 * Reads a sequence of bytes preceeded by its length as a varint.
 * @name BufferReader#bytes
 * @function
 * @returns {Buffer} Value read
 */

BufferReader._configure();