import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Employee } from 'src/app/shared/models';

export const EmployeeActions = createActionGroup({
  source: 'Employee',
  events: {
    'Load Employees': emptyProps(),
    'Load Employees Success': props<{ employees: Employee[] }>(),
    'Load Employees Failure': props<{ error: Error }>(),
    'Load Employee': props<{ employeeId: string }>(),
    'Load Employee Success': props<{ employee: Employee }>(),
    'Load Employee Failure': props<{ error: Error }>(),
    'Add Employee': props<{ employee: Employee }>(),
    'Add Employee Success': props<{ employee: Employee }>(),
    'Add Employee Failure': props<{ error: Error }>(),
    'Remove Employee': props<{ employeeId: string }>(),
    'Remove Employee Success': props<{ employeeId: string }>(),
    'Remove Employee Failure': props<{ error: Error }>(),
    'Update Employee': props<{
      employeeId: string;
      employee: Employee;
    }>(),
    'Update Employee Success': props<{
      employeeId: string;
      employee: Employee;
    }>(),
    'Update Employee Failure': props<{ error: Error }>(),
  },
});
