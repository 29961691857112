/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { DialerCurrentCall, DialerSession } from "./call";
import { ExecutiveUserContainer } from "./executive";
import { Struct } from "./google/protobuf/struct";
import { DialerWebRtc } from "./misc";
import {
  DialerRole,
  dialerRoleFromJSON,
  dialerRoleToJSON,
  UserRole,
  userRoleFromJSON,
  userRoleToJSON,
  UserType,
  userTypeFromJSON,
  userTypeToJSON,
} from "./user-enum";

export const protobufPackage = "";

export interface Employee {
  id?: string | undefined;
  userType?: UserType | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  npn?: string | undefined;
  profileImage?: string | undefined;
  name?: string | undefined;
  userRole?: UserRole | undefined;
  dialerRole?: DialerRole | undefined;
  canImpersonate?: boolean | undefined;
  skills?: { [key: string]: any } | undefined;
  currentAgency?: Employee_DialerAgency | undefined;
  dialerSession?: DialerSession | undefined;
  setupDone?: boolean | undefined;
  webrtc?: DialerWebRtc | undefined;
  currentCall?: DialerCurrentCall | undefined;
  executive?: ExecutiveUserContainer | undefined;
  agencyId?: string | undefined;
}

export interface Employee_DialerAgency {
  id?: string | undefined;
  name?: string | undefined;
}

function createBaseEmployee(): Employee {
  return {
    id: undefined,
    userType: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    npn: undefined,
    profileImage: undefined,
    name: undefined,
    userRole: undefined,
    dialerRole: undefined,
    canImpersonate: undefined,
    skills: undefined,
    currentAgency: undefined,
    dialerSession: undefined,
    setupDone: undefined,
    webrtc: undefined,
    currentCall: undefined,
    executive: undefined,
    agencyId: undefined,
  };
}

export const Employee = {
  encode(message: Employee, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.userType !== undefined) {
      writer.uint32(16).int32(message.userType);
    }
    if (message.firstName !== undefined) {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.email !== undefined) {
      writer.uint32(42).string(message.email);
    }
    if (message.npn !== undefined) {
      writer.uint32(50).string(message.npn);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(58).string(message.profileImage);
    }
    if (message.name !== undefined) {
      writer.uint32(66).string(message.name);
    }
    if (message.userRole !== undefined) {
      writer.uint32(72).int32(message.userRole);
    }
    if (message.dialerRole !== undefined) {
      writer.uint32(80).int32(message.dialerRole);
    }
    if (message.canImpersonate !== undefined) {
      writer.uint32(88).bool(message.canImpersonate);
    }
    if (message.skills !== undefined) {
      Struct.encode(Struct.wrap(message.skills), writer.uint32(98).fork()).ldelim();
    }
    if (message.currentAgency !== undefined) {
      Employee_DialerAgency.encode(message.currentAgency, writer.uint32(106).fork()).ldelim();
    }
    if (message.dialerSession !== undefined) {
      DialerSession.encode(message.dialerSession, writer.uint32(114).fork()).ldelim();
    }
    if (message.setupDone !== undefined) {
      writer.uint32(120).bool(message.setupDone);
    }
    if (message.webrtc !== undefined) {
      DialerWebRtc.encode(message.webrtc, writer.uint32(130).fork()).ldelim();
    }
    if (message.currentCall !== undefined) {
      DialerCurrentCall.encode(message.currentCall, writer.uint32(138).fork()).ldelim();
    }
    if (message.executive !== undefined) {
      ExecutiveUserContainer.encode(message.executive, writer.uint32(146).fork()).ldelim();
    }
    if (message.agencyId !== undefined) {
      writer.uint32(154).string(message.agencyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Employee {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmployee();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.name = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.userRole = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.dialerRole = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.canImpersonate = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.skills = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.currentAgency = Employee_DialerAgency.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.dialerSession = DialerSession.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.setupDone = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.webrtc = DialerWebRtc.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.currentCall = DialerCurrentCall.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.executive = ExecutiveUserContainer.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.agencyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Employee {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      userType: isSet(object.userType) ? userTypeFromJSON(object.userType) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      userRole: isSet(object.userRole) ? userRoleFromJSON(object.userRole) : undefined,
      dialerRole: isSet(object.dialerRole) ? dialerRoleFromJSON(object.dialerRole) : undefined,
      canImpersonate: isSet(object.canImpersonate) ? Boolean(object.canImpersonate) : undefined,
      skills: isObject(object.skills) ? object.skills : undefined,
      currentAgency: isSet(object.currentAgency) ? Employee_DialerAgency.fromJSON(object.currentAgency) : undefined,
      dialerSession: isSet(object.dialerSession) ? DialerSession.fromJSON(object.dialerSession) : undefined,
      setupDone: isSet(object.setupDone) ? Boolean(object.setupDone) : undefined,
      webrtc: isSet(object.webrtc) ? DialerWebRtc.fromJSON(object.webrtc) : undefined,
      currentCall: isSet(object.currentCall) ? DialerCurrentCall.fromJSON(object.currentCall) : undefined,
      executive: isSet(object.executive) ? ExecutiveUserContainer.fromJSON(object.executive) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
    };
  },

  toJSON(message: Employee): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userType !== undefined &&
      (obj.userType = message.userType !== undefined ? userTypeToJSON(message.userType) : undefined);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.email !== undefined && (obj.email = message.email);
    message.npn !== undefined && (obj.npn = message.npn);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.name !== undefined && (obj.name = message.name);
    message.userRole !== undefined &&
      (obj.userRole = message.userRole !== undefined ? userRoleToJSON(message.userRole) : undefined);
    message.dialerRole !== undefined &&
      (obj.dialerRole = message.dialerRole !== undefined ? dialerRoleToJSON(message.dialerRole) : undefined);
    message.canImpersonate !== undefined && (obj.canImpersonate = message.canImpersonate);
    message.skills !== undefined && (obj.skills = message.skills);
    message.currentAgency !== undefined &&
      (obj.currentAgency = message.currentAgency ? Employee_DialerAgency.toJSON(message.currentAgency) : undefined);
    message.dialerSession !== undefined &&
      (obj.dialerSession = message.dialerSession ? DialerSession.toJSON(message.dialerSession) : undefined);
    message.setupDone !== undefined && (obj.setupDone = message.setupDone);
    message.webrtc !== undefined && (obj.webrtc = message.webrtc ? DialerWebRtc.toJSON(message.webrtc) : undefined);
    message.currentCall !== undefined &&
      (obj.currentCall = message.currentCall ? DialerCurrentCall.toJSON(message.currentCall) : undefined);
    message.executive !== undefined &&
      (obj.executive = message.executive ? ExecutiveUserContainer.toJSON(message.executive) : undefined);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    return obj;
  },

  create<I extends Exact<DeepPartial<Employee>, I>>(base?: I): Employee {
    return Employee.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Employee>, I>>(object: I): Employee {
    const message = createBaseEmployee();
    message.id = object.id ?? undefined;
    message.userType = object.userType ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.email = object.email ?? undefined;
    message.npn = object.npn ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.name = object.name ?? undefined;
    message.userRole = object.userRole ?? undefined;
    message.dialerRole = object.dialerRole ?? undefined;
    message.canImpersonate = object.canImpersonate ?? undefined;
    message.skills = object.skills ?? undefined;
    message.currentAgency = (object.currentAgency !== undefined && object.currentAgency !== null)
      ? Employee_DialerAgency.fromPartial(object.currentAgency)
      : undefined;
    message.dialerSession = (object.dialerSession !== undefined && object.dialerSession !== null)
      ? DialerSession.fromPartial(object.dialerSession)
      : undefined;
    message.setupDone = object.setupDone ?? undefined;
    message.webrtc = (object.webrtc !== undefined && object.webrtc !== null)
      ? DialerWebRtc.fromPartial(object.webrtc)
      : undefined;
    message.currentCall = (object.currentCall !== undefined && object.currentCall !== null)
      ? DialerCurrentCall.fromPartial(object.currentCall)
      : undefined;
    message.executive = (object.executive !== undefined && object.executive !== null)
      ? ExecutiveUserContainer.fromPartial(object.executive)
      : undefined;
    message.agencyId = object.agencyId ?? undefined;
    return message;
  },
};

function createBaseEmployee_DialerAgency(): Employee_DialerAgency {
  return { id: undefined, name: undefined };
}

export const Employee_DialerAgency = {
  encode(message: Employee_DialerAgency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Employee_DialerAgency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmployee_DialerAgency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Employee_DialerAgency {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: Employee_DialerAgency): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Employee_DialerAgency>, I>>(base?: I): Employee_DialerAgency {
    return Employee_DialerAgency.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Employee_DialerAgency>, I>>(object: I): Employee_DialerAgency {
    const message = createBaseEmployee_DialerAgency();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
