/* eslint-disable */

export const protobufPackage = "";

export enum CommissionType {
  CT_NONE = 0,
  CT_STREET_LEVEL = 1,
  CT_OVERRIDE = 2,
  CT_HEAP = 3,
  CT_CUSTOM = 99,
  UNRECOGNIZED = -1,
}

export function commissionTypeFromJSON(object: any): CommissionType {
  switch (object) {
    case 0:
    case "CT_NONE":
      return CommissionType.CT_NONE;
    case 1:
    case "CT_STREET_LEVEL":
      return CommissionType.CT_STREET_LEVEL;
    case 2:
    case "CT_OVERRIDE":
      return CommissionType.CT_OVERRIDE;
    case 3:
    case "CT_HEAP":
      return CommissionType.CT_HEAP;
    case 99:
    case "CT_CUSTOM":
      return CommissionType.CT_CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CommissionType.UNRECOGNIZED;
  }
}

export function commissionTypeToJSON(object: CommissionType): string {
  switch (object) {
    case CommissionType.CT_NONE:
      return "CT_NONE";
    case CommissionType.CT_STREET_LEVEL:
      return "CT_STREET_LEVEL";
    case CommissionType.CT_OVERRIDE:
      return "CT_OVERRIDE";
    case CommissionType.CT_HEAP:
      return "CT_HEAP";
    case CommissionType.CT_CUSTOM:
      return "CT_CUSTOM";
    case CommissionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
