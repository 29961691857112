/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { CommissionType, commissionTypeFromJSON, commissionTypeToJSON } from "./commissions-enum";
import { Timestamp } from "./google/protobuf/timestamp";
import { DateContainer } from "./misc";
import { UserType, userTypeFromJSON, userTypeToJSON } from "./user-enum";

export const protobufPackage = "";

export interface ExecutiveUserContainer {
  access: ExecutiveUserContainer_Access[];
}

export interface ExecutiveUserContainer_Access {
  id?: string | undefined;
  userType?: UserType | undefined;
}

export interface ExecutiveContainer {
  singleExecutiveRules: SingleExecutiveRule[];
  /** repeated AggregatedExecutiveRule aggregatedExecutiveRulesCommissions = 3; */
  aggregatedExecutiveRules: AggregatedExecutiveRule[];
}

export interface SingleExecutiveContainer {
  singleExecutiveRules: SingleExecutiveRule[];
}

export interface AggregatedExecutiveContainer {
  aggregatedExecutiveRules: AggregatedExecutiveRule[];
}

export interface AggregatedExecutiveCommissionsContainer {
  aggregatedExecutiveRulesCommissions: AggregatedExecutiveRuleCommissions[];
}

export interface SingleExecutiveRule {
  executiveId?: string | undefined;
  ladderRule?: ExecutiveLadderRule | undefined;
  amIManagingAgent?: boolean | undefined;
  advancedPayoutRules?: AdvancedPayoutRuleContainer | undefined;
}

export interface AggregatedExecutiveRule {
  executiveLadder: string[];
  ladderRule?: ExecutiveLadderRule | undefined;
  amIManagingAgent?: boolean | undefined;
  advancedPayoutRuleMap: { [key: string]: AdvancedPayoutRuleContainer };
}

export interface AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry {
  key: string;
  value: AdvancedPayoutRuleContainer | undefined;
}

export interface AggregatedExecutiveRuleCommissions {
  executiveLadder: string[];
  ladderRule?: ExecutiveLadderRule | undefined;
  executiveTierLadder: { [key: string]: TierLevelContainer };
  advancedPayoutRuleMap: { [key: string]: AdvancedPayoutRuleContainer };
  amIManagingAgent?: boolean | undefined;
}

export interface AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry {
  key: string;
  value: TierLevelContainer | undefined;
}

export interface AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry {
  key: string;
  value: AdvancedPayoutRuleContainer | undefined;
}

export interface ExecutiveProcessingOptions {
  done?: boolean | undefined;
  delete?: boolean | undefined;
}

export interface ExecutiveLadderRule {
  carrier?: ExecutiveLadderRule_Carrier | undefined;
  startDate: Date | undefined;
  endDate?: Date | undefined;
}

export interface ExecutiveLadderRule_Carrier {
  id?: string | undefined;
  name?: string | undefined;
}

export interface AdvancedPayoutRuleContainer {
  advancedPayoutRules: AdvancedPayoutRule[];
}

export interface AdvancedPayoutRule {
  payoutReceivers: PayoutReceiver[];
  startDate?: DateContainer | undefined;
  endDate?:
    | DateContainer
    | undefined;
  /** optional Condition endCondiation = 5; <--- evtl für sowas wie "Loan aufgebraucht" */
  payoutRuleType?: AdvancedPayoutRule_PayoutRuleType | undefined;
}

export enum AdvancedPayoutRule_PayoutRuleType {
  PR_NONE = 0,
  PR_DISABLED_LOA = 1,
  UNRECOGNIZED = -1,
}

export function advancedPayoutRule_PayoutRuleTypeFromJSON(object: any): AdvancedPayoutRule_PayoutRuleType {
  switch (object) {
    case 0:
    case "PR_NONE":
      return AdvancedPayoutRule_PayoutRuleType.PR_NONE;
    case 1:
    case "PR_DISABLED_LOA":
      return AdvancedPayoutRule_PayoutRuleType.PR_DISABLED_LOA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AdvancedPayoutRule_PayoutRuleType.UNRECOGNIZED;
  }
}

export function advancedPayoutRule_PayoutRuleTypeToJSON(object: AdvancedPayoutRule_PayoutRuleType): string {
  switch (object) {
    case AdvancedPayoutRule_PayoutRuleType.PR_NONE:
      return "PR_NONE";
    case AdvancedPayoutRule_PayoutRuleType.PR_DISABLED_LOA:
      return "PR_DISABLED_LOA";
    case AdvancedPayoutRule_PayoutRuleType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PayoutReceiver {
  executiveId?: string | undefined;
  percentage?: number | undefined;
}

export interface TierLevelContainer {
  tierLevels: TierLevel[];
  doesPayOutAllChildren?: TierLevelContainer_PayOutChildren | undefined;
}

export interface TierLevelContainer_PayOutChildren {
  startDate?: DateContainer | undefined;
  excluded?: TierLevelContainer_PayOutChildren_ExcludedContainer | undefined;
}

export interface TierLevelContainer_PayOutChildren_ExcludedContainer {
  excludedIds: string[];
}

export interface TierLevel {
  payoutDefinition?: PayoutDefinition | undefined;
  ladderRule?: ExecutiveLadderRule | undefined;
  userType?: UserType | undefined;
  commissionType?: CommissionType | undefined;
}

export interface PayoutDefinition {
  levelName?: string | undefined;
  templateAgencyId?: string | undefined;
  payerId?: string | undefined;
}

function createBaseExecutiveUserContainer(): ExecutiveUserContainer {
  return { access: [] };
}

export const ExecutiveUserContainer = {
  encode(message: ExecutiveUserContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.access) {
      ExecutiveUserContainer_Access.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExecutiveUserContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExecutiveUserContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.access.push(ExecutiveUserContainer_Access.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExecutiveUserContainer {
    return {
      access: Array.isArray(object?.access)
        ? object.access.map((e: any) => ExecutiveUserContainer_Access.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ExecutiveUserContainer): unknown {
    const obj: any = {};
    if (message.access) {
      obj.access = message.access.map((e) => e ? ExecutiveUserContainer_Access.toJSON(e) : undefined);
    } else {
      obj.access = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExecutiveUserContainer>, I>>(base?: I): ExecutiveUserContainer {
    return ExecutiveUserContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExecutiveUserContainer>, I>>(object: I): ExecutiveUserContainer {
    const message = createBaseExecutiveUserContainer();
    message.access = object.access?.map((e) => ExecutiveUserContainer_Access.fromPartial(e)) || [];
    return message;
  },
};

function createBaseExecutiveUserContainer_Access(): ExecutiveUserContainer_Access {
  return { id: undefined, userType: undefined };
}

export const ExecutiveUserContainer_Access = {
  encode(message: ExecutiveUserContainer_Access, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.userType !== undefined) {
      writer.uint32(16).int32(message.userType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExecutiveUserContainer_Access {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExecutiveUserContainer_Access();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExecutiveUserContainer_Access {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      userType: isSet(object.userType) ? userTypeFromJSON(object.userType) : undefined,
    };
  },

  toJSON(message: ExecutiveUserContainer_Access): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userType !== undefined &&
      (obj.userType = message.userType !== undefined ? userTypeToJSON(message.userType) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExecutiveUserContainer_Access>, I>>(base?: I): ExecutiveUserContainer_Access {
    return ExecutiveUserContainer_Access.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExecutiveUserContainer_Access>, I>>(
    object: I,
  ): ExecutiveUserContainer_Access {
    const message = createBaseExecutiveUserContainer_Access();
    message.id = object.id ?? undefined;
    message.userType = object.userType ?? undefined;
    return message;
  },
};

function createBaseExecutiveContainer(): ExecutiveContainer {
  return { singleExecutiveRules: [], aggregatedExecutiveRules: [] };
}

export const ExecutiveContainer = {
  encode(message: ExecutiveContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.singleExecutiveRules) {
      SingleExecutiveRule.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.aggregatedExecutiveRules) {
      AggregatedExecutiveRule.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExecutiveContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExecutiveContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.singleExecutiveRules.push(SingleExecutiveRule.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.aggregatedExecutiveRules.push(AggregatedExecutiveRule.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExecutiveContainer {
    return {
      singleExecutiveRules: Array.isArray(object?.singleExecutiveRules)
        ? object.singleExecutiveRules.map((e: any) => SingleExecutiveRule.fromJSON(e))
        : [],
      aggregatedExecutiveRules: Array.isArray(object?.aggregatedExecutiveRules)
        ? object.aggregatedExecutiveRules.map((e: any) => AggregatedExecutiveRule.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ExecutiveContainer): unknown {
    const obj: any = {};
    if (message.singleExecutiveRules) {
      obj.singleExecutiveRules = message.singleExecutiveRules.map((e) => e ? SingleExecutiveRule.toJSON(e) : undefined);
    } else {
      obj.singleExecutiveRules = [];
    }
    if (message.aggregatedExecutiveRules) {
      obj.aggregatedExecutiveRules = message.aggregatedExecutiveRules.map((e) =>
        e ? AggregatedExecutiveRule.toJSON(e) : undefined
      );
    } else {
      obj.aggregatedExecutiveRules = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExecutiveContainer>, I>>(base?: I): ExecutiveContainer {
    return ExecutiveContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExecutiveContainer>, I>>(object: I): ExecutiveContainer {
    const message = createBaseExecutiveContainer();
    message.singleExecutiveRules = object.singleExecutiveRules?.map((e) => SingleExecutiveRule.fromPartial(e)) || [];
    message.aggregatedExecutiveRules =
      object.aggregatedExecutiveRules?.map((e) => AggregatedExecutiveRule.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSingleExecutiveContainer(): SingleExecutiveContainer {
  return { singleExecutiveRules: [] };
}

export const SingleExecutiveContainer = {
  encode(message: SingleExecutiveContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.singleExecutiveRules) {
      SingleExecutiveRule.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SingleExecutiveContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSingleExecutiveContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.singleExecutiveRules.push(SingleExecutiveRule.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SingleExecutiveContainer {
    return {
      singleExecutiveRules: Array.isArray(object?.singleExecutiveRules)
        ? object.singleExecutiveRules.map((e: any) => SingleExecutiveRule.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SingleExecutiveContainer): unknown {
    const obj: any = {};
    if (message.singleExecutiveRules) {
      obj.singleExecutiveRules = message.singleExecutiveRules.map((e) => e ? SingleExecutiveRule.toJSON(e) : undefined);
    } else {
      obj.singleExecutiveRules = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SingleExecutiveContainer>, I>>(base?: I): SingleExecutiveContainer {
    return SingleExecutiveContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SingleExecutiveContainer>, I>>(object: I): SingleExecutiveContainer {
    const message = createBaseSingleExecutiveContainer();
    message.singleExecutiveRules = object.singleExecutiveRules?.map((e) => SingleExecutiveRule.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAggregatedExecutiveContainer(): AggregatedExecutiveContainer {
  return { aggregatedExecutiveRules: [] };
}

export const AggregatedExecutiveContainer = {
  encode(message: AggregatedExecutiveContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.aggregatedExecutiveRules) {
      AggregatedExecutiveRule.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AggregatedExecutiveContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAggregatedExecutiveContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.aggregatedExecutiveRules.push(AggregatedExecutiveRule.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AggregatedExecutiveContainer {
    return {
      aggregatedExecutiveRules: Array.isArray(object?.aggregatedExecutiveRules)
        ? object.aggregatedExecutiveRules.map((e: any) => AggregatedExecutiveRule.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AggregatedExecutiveContainer): unknown {
    const obj: any = {};
    if (message.aggregatedExecutiveRules) {
      obj.aggregatedExecutiveRules = message.aggregatedExecutiveRules.map((e) =>
        e ? AggregatedExecutiveRule.toJSON(e) : undefined
      );
    } else {
      obj.aggregatedExecutiveRules = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AggregatedExecutiveContainer>, I>>(base?: I): AggregatedExecutiveContainer {
    return AggregatedExecutiveContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AggregatedExecutiveContainer>, I>>(object: I): AggregatedExecutiveContainer {
    const message = createBaseAggregatedExecutiveContainer();
    message.aggregatedExecutiveRules =
      object.aggregatedExecutiveRules?.map((e) => AggregatedExecutiveRule.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAggregatedExecutiveCommissionsContainer(): AggregatedExecutiveCommissionsContainer {
  return { aggregatedExecutiveRulesCommissions: [] };
}

export const AggregatedExecutiveCommissionsContainer = {
  encode(message: AggregatedExecutiveCommissionsContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.aggregatedExecutiveRulesCommissions) {
      AggregatedExecutiveRuleCommissions.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AggregatedExecutiveCommissionsContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAggregatedExecutiveCommissionsContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.aggregatedExecutiveRulesCommissions.push(
            AggregatedExecutiveRuleCommissions.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AggregatedExecutiveCommissionsContainer {
    return {
      aggregatedExecutiveRulesCommissions: Array.isArray(object?.aggregatedExecutiveRulesCommissions)
        ? object.aggregatedExecutiveRulesCommissions.map((e: any) => AggregatedExecutiveRuleCommissions.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AggregatedExecutiveCommissionsContainer): unknown {
    const obj: any = {};
    if (message.aggregatedExecutiveRulesCommissions) {
      obj.aggregatedExecutiveRulesCommissions = message.aggregatedExecutiveRulesCommissions.map((e) =>
        e ? AggregatedExecutiveRuleCommissions.toJSON(e) : undefined
      );
    } else {
      obj.aggregatedExecutiveRulesCommissions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AggregatedExecutiveCommissionsContainer>, I>>(
    base?: I,
  ): AggregatedExecutiveCommissionsContainer {
    return AggregatedExecutiveCommissionsContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AggregatedExecutiveCommissionsContainer>, I>>(
    object: I,
  ): AggregatedExecutiveCommissionsContainer {
    const message = createBaseAggregatedExecutiveCommissionsContainer();
    message.aggregatedExecutiveRulesCommissions =
      object.aggregatedExecutiveRulesCommissions?.map((e) => AggregatedExecutiveRuleCommissions.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSingleExecutiveRule(): SingleExecutiveRule {
  return { executiveId: undefined, ladderRule: undefined, amIManagingAgent: undefined, advancedPayoutRules: undefined };
}

export const SingleExecutiveRule = {
  encode(message: SingleExecutiveRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.executiveId !== undefined) {
      writer.uint32(10).string(message.executiveId);
    }
    if (message.ladderRule !== undefined) {
      ExecutiveLadderRule.encode(message.ladderRule, writer.uint32(18).fork()).ldelim();
    }
    if (message.amIManagingAgent !== undefined) {
      writer.uint32(24).bool(message.amIManagingAgent);
    }
    if (message.advancedPayoutRules !== undefined) {
      AdvancedPayoutRuleContainer.encode(message.advancedPayoutRules, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SingleExecutiveRule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSingleExecutiveRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.executiveId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ladderRule = ExecutiveLadderRule.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.amIManagingAgent = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.advancedPayoutRules = AdvancedPayoutRuleContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SingleExecutiveRule {
    return {
      executiveId: isSet(object.executiveId) ? String(object.executiveId) : undefined,
      ladderRule: isSet(object.ladderRule) ? ExecutiveLadderRule.fromJSON(object.ladderRule) : undefined,
      amIManagingAgent: isSet(object.amIManagingAgent) ? Boolean(object.amIManagingAgent) : undefined,
      advancedPayoutRules: isSet(object.advancedPayoutRules)
        ? AdvancedPayoutRuleContainer.fromJSON(object.advancedPayoutRules)
        : undefined,
    };
  },

  toJSON(message: SingleExecutiveRule): unknown {
    const obj: any = {};
    message.executiveId !== undefined && (obj.executiveId = message.executiveId);
    message.ladderRule !== undefined &&
      (obj.ladderRule = message.ladderRule ? ExecutiveLadderRule.toJSON(message.ladderRule) : undefined);
    message.amIManagingAgent !== undefined && (obj.amIManagingAgent = message.amIManagingAgent);
    message.advancedPayoutRules !== undefined && (obj.advancedPayoutRules = message.advancedPayoutRules
      ? AdvancedPayoutRuleContainer.toJSON(message.advancedPayoutRules)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SingleExecutiveRule>, I>>(base?: I): SingleExecutiveRule {
    return SingleExecutiveRule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SingleExecutiveRule>, I>>(object: I): SingleExecutiveRule {
    const message = createBaseSingleExecutiveRule();
    message.executiveId = object.executiveId ?? undefined;
    message.ladderRule = (object.ladderRule !== undefined && object.ladderRule !== null)
      ? ExecutiveLadderRule.fromPartial(object.ladderRule)
      : undefined;
    message.amIManagingAgent = object.amIManagingAgent ?? undefined;
    message.advancedPayoutRules = (object.advancedPayoutRules !== undefined && object.advancedPayoutRules !== null)
      ? AdvancedPayoutRuleContainer.fromPartial(object.advancedPayoutRules)
      : undefined;
    return message;
  },
};

function createBaseAggregatedExecutiveRule(): AggregatedExecutiveRule {
  return { executiveLadder: [], ladderRule: undefined, amIManagingAgent: undefined, advancedPayoutRuleMap: {} };
}

export const AggregatedExecutiveRule = {
  encode(message: AggregatedExecutiveRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.executiveLadder) {
      writer.uint32(10).string(v!);
    }
    if (message.ladderRule !== undefined) {
      ExecutiveLadderRule.encode(message.ladderRule, writer.uint32(18).fork()).ldelim();
    }
    if (message.amIManagingAgent !== undefined) {
      writer.uint32(24).bool(message.amIManagingAgent);
    }
    Object.entries(message.advancedPayoutRuleMap).forEach(([key, value]) => {
      AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry.encode({ key: key as any, value }, writer.uint32(34).fork())
        .ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AggregatedExecutiveRule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAggregatedExecutiveRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.executiveLadder.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ladderRule = ExecutiveLadderRule.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.amIManagingAgent = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.advancedPayoutRuleMap[entry4.key] = entry4.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AggregatedExecutiveRule {
    return {
      executiveLadder: Array.isArray(object?.executiveLadder) ? object.executiveLadder.map((e: any) => String(e)) : [],
      ladderRule: isSet(object.ladderRule) ? ExecutiveLadderRule.fromJSON(object.ladderRule) : undefined,
      amIManagingAgent: isSet(object.amIManagingAgent) ? Boolean(object.amIManagingAgent) : undefined,
      advancedPayoutRuleMap: isObject(object.advancedPayoutRuleMap)
        ? Object.entries(object.advancedPayoutRuleMap).reduce<{ [key: string]: AdvancedPayoutRuleContainer }>(
          (acc, [key, value]) => {
            acc[key] = AdvancedPayoutRuleContainer.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: AggregatedExecutiveRule): unknown {
    const obj: any = {};
    if (message.executiveLadder) {
      obj.executiveLadder = message.executiveLadder.map((e) => e);
    } else {
      obj.executiveLadder = [];
    }
    message.ladderRule !== undefined &&
      (obj.ladderRule = message.ladderRule ? ExecutiveLadderRule.toJSON(message.ladderRule) : undefined);
    message.amIManagingAgent !== undefined && (obj.amIManagingAgent = message.amIManagingAgent);
    obj.advancedPayoutRuleMap = {};
    if (message.advancedPayoutRuleMap) {
      Object.entries(message.advancedPayoutRuleMap).forEach(([k, v]) => {
        obj.advancedPayoutRuleMap[k] = AdvancedPayoutRuleContainer.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AggregatedExecutiveRule>, I>>(base?: I): AggregatedExecutiveRule {
    return AggregatedExecutiveRule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AggregatedExecutiveRule>, I>>(object: I): AggregatedExecutiveRule {
    const message = createBaseAggregatedExecutiveRule();
    message.executiveLadder = object.executiveLadder?.map((e) => e) || [];
    message.ladderRule = (object.ladderRule !== undefined && object.ladderRule !== null)
      ? ExecutiveLadderRule.fromPartial(object.ladderRule)
      : undefined;
    message.amIManagingAgent = object.amIManagingAgent ?? undefined;
    message.advancedPayoutRuleMap = Object.entries(object.advancedPayoutRuleMap ?? {}).reduce<
      { [key: string]: AdvancedPayoutRuleContainer }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = AdvancedPayoutRuleContainer.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseAggregatedExecutiveRule_AdvancedPayoutRuleMapEntry(): AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry {
  return { key: "", value: undefined };
}

export const AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry = {
  encode(
    message: AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      AdvancedPayoutRuleContainer.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAggregatedExecutiveRule_AdvancedPayoutRuleMapEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = AdvancedPayoutRuleContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? AdvancedPayoutRuleContainer.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? AdvancedPayoutRuleContainer.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry>, I>>(
    base?: I,
  ): AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry {
    return AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry>, I>>(
    object: I,
  ): AggregatedExecutiveRule_AdvancedPayoutRuleMapEntry {
    const message = createBaseAggregatedExecutiveRule_AdvancedPayoutRuleMapEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? AdvancedPayoutRuleContainer.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseAggregatedExecutiveRuleCommissions(): AggregatedExecutiveRuleCommissions {
  return {
    executiveLadder: [],
    ladderRule: undefined,
    executiveTierLadder: {},
    advancedPayoutRuleMap: {},
    amIManagingAgent: undefined,
  };
}

export const AggregatedExecutiveRuleCommissions = {
  encode(message: AggregatedExecutiveRuleCommissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.executiveLadder) {
      writer.uint32(10).string(v!);
    }
    if (message.ladderRule !== undefined) {
      ExecutiveLadderRule.encode(message.ladderRule, writer.uint32(18).fork()).ldelim();
    }
    Object.entries(message.executiveTierLadder).forEach(([key, value]) => {
      AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    Object.entries(message.advancedPayoutRuleMap).forEach(([key, value]) => {
      AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry.encode(
        { key: key as any, value },
        writer.uint32(34).fork(),
      ).ldelim();
    });
    if (message.amIManagingAgent !== undefined) {
      writer.uint32(40).bool(message.amIManagingAgent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AggregatedExecutiveRuleCommissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAggregatedExecutiveRuleCommissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.executiveLadder.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ladderRule = ExecutiveLadderRule.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.executiveTierLadder[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.advancedPayoutRuleMap[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.amIManagingAgent = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AggregatedExecutiveRuleCommissions {
    return {
      executiveLadder: Array.isArray(object?.executiveLadder) ? object.executiveLadder.map((e: any) => String(e)) : [],
      ladderRule: isSet(object.ladderRule) ? ExecutiveLadderRule.fromJSON(object.ladderRule) : undefined,
      executiveTierLadder: isObject(object.executiveTierLadder)
        ? Object.entries(object.executiveTierLadder).reduce<{ [key: string]: TierLevelContainer }>(
          (acc, [key, value]) => {
            acc[key] = TierLevelContainer.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      advancedPayoutRuleMap: isObject(object.advancedPayoutRuleMap)
        ? Object.entries(object.advancedPayoutRuleMap).reduce<{ [key: string]: AdvancedPayoutRuleContainer }>(
          (acc, [key, value]) => {
            acc[key] = AdvancedPayoutRuleContainer.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      amIManagingAgent: isSet(object.amIManagingAgent) ? Boolean(object.amIManagingAgent) : undefined,
    };
  },

  toJSON(message: AggregatedExecutiveRuleCommissions): unknown {
    const obj: any = {};
    if (message.executiveLadder) {
      obj.executiveLadder = message.executiveLadder.map((e) => e);
    } else {
      obj.executiveLadder = [];
    }
    message.ladderRule !== undefined &&
      (obj.ladderRule = message.ladderRule ? ExecutiveLadderRule.toJSON(message.ladderRule) : undefined);
    obj.executiveTierLadder = {};
    if (message.executiveTierLadder) {
      Object.entries(message.executiveTierLadder).forEach(([k, v]) => {
        obj.executiveTierLadder[k] = TierLevelContainer.toJSON(v);
      });
    }
    obj.advancedPayoutRuleMap = {};
    if (message.advancedPayoutRuleMap) {
      Object.entries(message.advancedPayoutRuleMap).forEach(([k, v]) => {
        obj.advancedPayoutRuleMap[k] = AdvancedPayoutRuleContainer.toJSON(v);
      });
    }
    message.amIManagingAgent !== undefined && (obj.amIManagingAgent = message.amIManagingAgent);
    return obj;
  },

  create<I extends Exact<DeepPartial<AggregatedExecutiveRuleCommissions>, I>>(
    base?: I,
  ): AggregatedExecutiveRuleCommissions {
    return AggregatedExecutiveRuleCommissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AggregatedExecutiveRuleCommissions>, I>>(
    object: I,
  ): AggregatedExecutiveRuleCommissions {
    const message = createBaseAggregatedExecutiveRuleCommissions();
    message.executiveLadder = object.executiveLadder?.map((e) => e) || [];
    message.ladderRule = (object.ladderRule !== undefined && object.ladderRule !== null)
      ? ExecutiveLadderRule.fromPartial(object.ladderRule)
      : undefined;
    message.executiveTierLadder = Object.entries(object.executiveTierLadder ?? {}).reduce<
      { [key: string]: TierLevelContainer }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = TierLevelContainer.fromPartial(value);
      }
      return acc;
    }, {});
    message.advancedPayoutRuleMap = Object.entries(object.advancedPayoutRuleMap ?? {}).reduce<
      { [key: string]: AdvancedPayoutRuleContainer }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = AdvancedPayoutRuleContainer.fromPartial(value);
      }
      return acc;
    }, {});
    message.amIManagingAgent = object.amIManagingAgent ?? undefined;
    return message;
  },
};

function createBaseAggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry(): AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry {
  return { key: "", value: undefined };
}

export const AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry = {
  encode(
    message: AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      TierLevelContainer.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = TierLevelContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? TierLevelContainer.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? TierLevelContainer.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry>, I>>(
    base?: I,
  ): AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry {
    return AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry>, I>>(
    object: I,
  ): AggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry {
    const message = createBaseAggregatedExecutiveRuleCommissions_ExecutiveTierLadderEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? TierLevelContainer.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseAggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry(): AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry {
  return { key: "", value: undefined };
}

export const AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry = {
  encode(
    message: AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      AdvancedPayoutRuleContainer.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = AdvancedPayoutRuleContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? AdvancedPayoutRuleContainer.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? AdvancedPayoutRuleContainer.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry>, I>>(
    base?: I,
  ): AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry {
    return AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry>, I>>(
    object: I,
  ): AggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry {
    const message = createBaseAggregatedExecutiveRuleCommissions_AdvancedPayoutRuleMapEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? AdvancedPayoutRuleContainer.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseExecutiveProcessingOptions(): ExecutiveProcessingOptions {
  return { done: undefined, delete: undefined };
}

export const ExecutiveProcessingOptions = {
  encode(message: ExecutiveProcessingOptions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.done !== undefined) {
      writer.uint32(8).bool(message.done);
    }
    if (message.delete !== undefined) {
      writer.uint32(16).bool(message.delete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExecutiveProcessingOptions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExecutiveProcessingOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.done = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.delete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExecutiveProcessingOptions {
    return {
      done: isSet(object.done) ? Boolean(object.done) : undefined,
      delete: isSet(object.delete) ? Boolean(object.delete) : undefined,
    };
  },

  toJSON(message: ExecutiveProcessingOptions): unknown {
    const obj: any = {};
    message.done !== undefined && (obj.done = message.done);
    message.delete !== undefined && (obj.delete = message.delete);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExecutiveProcessingOptions>, I>>(base?: I): ExecutiveProcessingOptions {
    return ExecutiveProcessingOptions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExecutiveProcessingOptions>, I>>(object: I): ExecutiveProcessingOptions {
    const message = createBaseExecutiveProcessingOptions();
    message.done = object.done ?? undefined;
    message.delete = object.delete ?? undefined;
    return message;
  },
};

function createBaseExecutiveLadderRule(): ExecutiveLadderRule {
  return { carrier: undefined, startDate: undefined, endDate: undefined };
}

export const ExecutiveLadderRule = {
  encode(message: ExecutiveLadderRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.carrier !== undefined) {
      ExecutiveLadderRule_Carrier.encode(message.carrier, writer.uint32(10).fork()).ldelim();
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExecutiveLadderRule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExecutiveLadderRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.carrier = ExecutiveLadderRule_Carrier.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExecutiveLadderRule {
    return {
      carrier: isSet(object.carrier) ? ExecutiveLadderRule_Carrier.fromJSON(object.carrier) : undefined,
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? fromJsonTimestamp(object.endDate) : undefined,
    };
  },

  toJSON(message: ExecutiveLadderRule): unknown {
    const obj: any = {};
    message.carrier !== undefined &&
      (obj.carrier = message.carrier ? ExecutiveLadderRule_Carrier.toJSON(message.carrier) : undefined);
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<ExecutiveLadderRule>, I>>(base?: I): ExecutiveLadderRule {
    return ExecutiveLadderRule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExecutiveLadderRule>, I>>(object: I): ExecutiveLadderRule {
    const message = createBaseExecutiveLadderRule();
    message.carrier = (object.carrier !== undefined && object.carrier !== null)
      ? ExecutiveLadderRule_Carrier.fromPartial(object.carrier)
      : undefined;
    message.startDate = object.startDate ?? undefined;
    message.endDate = object.endDate ?? undefined;
    return message;
  },
};

function createBaseExecutiveLadderRule_Carrier(): ExecutiveLadderRule_Carrier {
  return { id: undefined, name: undefined };
}

export const ExecutiveLadderRule_Carrier = {
  encode(message: ExecutiveLadderRule_Carrier, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExecutiveLadderRule_Carrier {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExecutiveLadderRule_Carrier();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExecutiveLadderRule_Carrier {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: ExecutiveLadderRule_Carrier): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExecutiveLadderRule_Carrier>, I>>(base?: I): ExecutiveLadderRule_Carrier {
    return ExecutiveLadderRule_Carrier.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExecutiveLadderRule_Carrier>, I>>(object: I): ExecutiveLadderRule_Carrier {
    const message = createBaseExecutiveLadderRule_Carrier();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseAdvancedPayoutRuleContainer(): AdvancedPayoutRuleContainer {
  return { advancedPayoutRules: [] };
}

export const AdvancedPayoutRuleContainer = {
  encode(message: AdvancedPayoutRuleContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.advancedPayoutRules) {
      AdvancedPayoutRule.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdvancedPayoutRuleContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdvancedPayoutRuleContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.advancedPayoutRules.push(AdvancedPayoutRule.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdvancedPayoutRuleContainer {
    return {
      advancedPayoutRules: Array.isArray(object?.advancedPayoutRules)
        ? object.advancedPayoutRules.map((e: any) => AdvancedPayoutRule.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AdvancedPayoutRuleContainer): unknown {
    const obj: any = {};
    if (message.advancedPayoutRules) {
      obj.advancedPayoutRules = message.advancedPayoutRules.map((e) => e ? AdvancedPayoutRule.toJSON(e) : undefined);
    } else {
      obj.advancedPayoutRules = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdvancedPayoutRuleContainer>, I>>(base?: I): AdvancedPayoutRuleContainer {
    return AdvancedPayoutRuleContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdvancedPayoutRuleContainer>, I>>(object: I): AdvancedPayoutRuleContainer {
    const message = createBaseAdvancedPayoutRuleContainer();
    message.advancedPayoutRules = object.advancedPayoutRules?.map((e) => AdvancedPayoutRule.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdvancedPayoutRule(): AdvancedPayoutRule {
  return { payoutReceivers: [], startDate: undefined, endDate: undefined, payoutRuleType: undefined };
}

export const AdvancedPayoutRule = {
  encode(message: AdvancedPayoutRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.payoutReceivers) {
      PayoutReceiver.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.startDate !== undefined) {
      DateContainer.encode(message.startDate, writer.uint32(18).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      DateContainer.encode(message.endDate, writer.uint32(26).fork()).ldelim();
    }
    if (message.payoutRuleType !== undefined) {
      writer.uint32(32).int32(message.payoutRuleType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdvancedPayoutRule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdvancedPayoutRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payoutReceivers.push(PayoutReceiver.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.payoutRuleType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdvancedPayoutRule {
    return {
      payoutReceivers: Array.isArray(object?.payoutReceivers)
        ? object.payoutReceivers.map((e: any) => PayoutReceiver.fromJSON(e))
        : [],
      startDate: isSet(object.startDate) ? DateContainer.fromJSON(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? DateContainer.fromJSON(object.endDate) : undefined,
      payoutRuleType: isSet(object.payoutRuleType)
        ? advancedPayoutRule_PayoutRuleTypeFromJSON(object.payoutRuleType)
        : undefined,
    };
  },

  toJSON(message: AdvancedPayoutRule): unknown {
    const obj: any = {};
    if (message.payoutReceivers) {
      obj.payoutReceivers = message.payoutReceivers.map((e) => e ? PayoutReceiver.toJSON(e) : undefined);
    } else {
      obj.payoutReceivers = [];
    }
    message.startDate !== undefined &&
      (obj.startDate = message.startDate ? DateContainer.toJSON(message.startDate) : undefined);
    message.endDate !== undefined &&
      (obj.endDate = message.endDate ? DateContainer.toJSON(message.endDate) : undefined);
    message.payoutRuleType !== undefined && (obj.payoutRuleType = message.payoutRuleType !== undefined
      ? advancedPayoutRule_PayoutRuleTypeToJSON(message.payoutRuleType)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdvancedPayoutRule>, I>>(base?: I): AdvancedPayoutRule {
    return AdvancedPayoutRule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdvancedPayoutRule>, I>>(object: I): AdvancedPayoutRule {
    const message = createBaseAdvancedPayoutRule();
    message.payoutReceivers = object.payoutReceivers?.map((e) => PayoutReceiver.fromPartial(e)) || [];
    message.startDate = (object.startDate !== undefined && object.startDate !== null)
      ? DateContainer.fromPartial(object.startDate)
      : undefined;
    message.endDate = (object.endDate !== undefined && object.endDate !== null)
      ? DateContainer.fromPartial(object.endDate)
      : undefined;
    message.payoutRuleType = object.payoutRuleType ?? undefined;
    return message;
  },
};

function createBasePayoutReceiver(): PayoutReceiver {
  return { executiveId: undefined, percentage: undefined };
}

export const PayoutReceiver = {
  encode(message: PayoutReceiver, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.executiveId !== undefined) {
      writer.uint32(10).string(message.executiveId);
    }
    if (message.percentage !== undefined) {
      writer.uint32(17).double(message.percentage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayoutReceiver {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayoutReceiver();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.executiveId = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.percentage = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PayoutReceiver {
    return {
      executiveId: isSet(object.executiveId) ? String(object.executiveId) : undefined,
      percentage: isSet(object.percentage) ? Number(object.percentage) : undefined,
    };
  },

  toJSON(message: PayoutReceiver): unknown {
    const obj: any = {};
    message.executiveId !== undefined && (obj.executiveId = message.executiveId);
    message.percentage !== undefined && (obj.percentage = message.percentage);
    return obj;
  },

  create<I extends Exact<DeepPartial<PayoutReceiver>, I>>(base?: I): PayoutReceiver {
    return PayoutReceiver.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PayoutReceiver>, I>>(object: I): PayoutReceiver {
    const message = createBasePayoutReceiver();
    message.executiveId = object.executiveId ?? undefined;
    message.percentage = object.percentage ?? undefined;
    return message;
  },
};

function createBaseTierLevelContainer(): TierLevelContainer {
  return { tierLevels: [], doesPayOutAllChildren: undefined };
}

export const TierLevelContainer = {
  encode(message: TierLevelContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tierLevels) {
      TierLevel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.doesPayOutAllChildren !== undefined) {
      TierLevelContainer_PayOutChildren.encode(message.doesPayOutAllChildren, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TierLevelContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTierLevelContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tierLevels.push(TierLevel.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.doesPayOutAllChildren = TierLevelContainer_PayOutChildren.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TierLevelContainer {
    return {
      tierLevels: Array.isArray(object?.tierLevels) ? object.tierLevels.map((e: any) => TierLevel.fromJSON(e)) : [],
      doesPayOutAllChildren: isSet(object.doesPayOutAllChildren)
        ? TierLevelContainer_PayOutChildren.fromJSON(object.doesPayOutAllChildren)
        : undefined,
    };
  },

  toJSON(message: TierLevelContainer): unknown {
    const obj: any = {};
    if (message.tierLevels) {
      obj.tierLevels = message.tierLevels.map((e) => e ? TierLevel.toJSON(e) : undefined);
    } else {
      obj.tierLevels = [];
    }
    message.doesPayOutAllChildren !== undefined && (obj.doesPayOutAllChildren = message.doesPayOutAllChildren
      ? TierLevelContainer_PayOutChildren.toJSON(message.doesPayOutAllChildren)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<TierLevelContainer>, I>>(base?: I): TierLevelContainer {
    return TierLevelContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TierLevelContainer>, I>>(object: I): TierLevelContainer {
    const message = createBaseTierLevelContainer();
    message.tierLevels = object.tierLevels?.map((e) => TierLevel.fromPartial(e)) || [];
    message.doesPayOutAllChildren =
      (object.doesPayOutAllChildren !== undefined && object.doesPayOutAllChildren !== null)
        ? TierLevelContainer_PayOutChildren.fromPartial(object.doesPayOutAllChildren)
        : undefined;
    return message;
  },
};

function createBaseTierLevelContainer_PayOutChildren(): TierLevelContainer_PayOutChildren {
  return { startDate: undefined, excluded: undefined };
}

export const TierLevelContainer_PayOutChildren = {
  encode(message: TierLevelContainer_PayOutChildren, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startDate !== undefined) {
      DateContainer.encode(message.startDate, writer.uint32(10).fork()).ldelim();
    }
    if (message.excluded !== undefined) {
      TierLevelContainer_PayOutChildren_ExcludedContainer.encode(message.excluded, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TierLevelContainer_PayOutChildren {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTierLevelContainer_PayOutChildren();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.excluded = TierLevelContainer_PayOutChildren_ExcludedContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TierLevelContainer_PayOutChildren {
    return {
      startDate: isSet(object.startDate) ? DateContainer.fromJSON(object.startDate) : undefined,
      excluded: isSet(object.excluded)
        ? TierLevelContainer_PayOutChildren_ExcludedContainer.fromJSON(object.excluded)
        : undefined,
    };
  },

  toJSON(message: TierLevelContainer_PayOutChildren): unknown {
    const obj: any = {};
    message.startDate !== undefined &&
      (obj.startDate = message.startDate ? DateContainer.toJSON(message.startDate) : undefined);
    message.excluded !== undefined && (obj.excluded = message.excluded
      ? TierLevelContainer_PayOutChildren_ExcludedContainer.toJSON(message.excluded)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<TierLevelContainer_PayOutChildren>, I>>(
    base?: I,
  ): TierLevelContainer_PayOutChildren {
    return TierLevelContainer_PayOutChildren.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TierLevelContainer_PayOutChildren>, I>>(
    object: I,
  ): TierLevelContainer_PayOutChildren {
    const message = createBaseTierLevelContainer_PayOutChildren();
    message.startDate = (object.startDate !== undefined && object.startDate !== null)
      ? DateContainer.fromPartial(object.startDate)
      : undefined;
    message.excluded = (object.excluded !== undefined && object.excluded !== null)
      ? TierLevelContainer_PayOutChildren_ExcludedContainer.fromPartial(object.excluded)
      : undefined;
    return message;
  },
};

function createBaseTierLevelContainer_PayOutChildren_ExcludedContainer(): TierLevelContainer_PayOutChildren_ExcludedContainer {
  return { excludedIds: [] };
}

export const TierLevelContainer_PayOutChildren_ExcludedContainer = {
  encode(
    message: TierLevelContainer_PayOutChildren_ExcludedContainer,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.excludedIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TierLevelContainer_PayOutChildren_ExcludedContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTierLevelContainer_PayOutChildren_ExcludedContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.excludedIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TierLevelContainer_PayOutChildren_ExcludedContainer {
    return { excludedIds: Array.isArray(object?.excludedIds) ? object.excludedIds.map((e: any) => String(e)) : [] };
  },

  toJSON(message: TierLevelContainer_PayOutChildren_ExcludedContainer): unknown {
    const obj: any = {};
    if (message.excludedIds) {
      obj.excludedIds = message.excludedIds.map((e) => e);
    } else {
      obj.excludedIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TierLevelContainer_PayOutChildren_ExcludedContainer>, I>>(
    base?: I,
  ): TierLevelContainer_PayOutChildren_ExcludedContainer {
    return TierLevelContainer_PayOutChildren_ExcludedContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TierLevelContainer_PayOutChildren_ExcludedContainer>, I>>(
    object: I,
  ): TierLevelContainer_PayOutChildren_ExcludedContainer {
    const message = createBaseTierLevelContainer_PayOutChildren_ExcludedContainer();
    message.excludedIds = object.excludedIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseTierLevel(): TierLevel {
  return { payoutDefinition: undefined, ladderRule: undefined, userType: undefined, commissionType: undefined };
}

export const TierLevel = {
  encode(message: TierLevel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.payoutDefinition !== undefined) {
      PayoutDefinition.encode(message.payoutDefinition, writer.uint32(10).fork()).ldelim();
    }
    if (message.ladderRule !== undefined) {
      ExecutiveLadderRule.encode(message.ladderRule, writer.uint32(18).fork()).ldelim();
    }
    if (message.userType !== undefined) {
      writer.uint32(24).int32(message.userType);
    }
    if (message.commissionType !== undefined) {
      writer.uint32(32).int32(message.commissionType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TierLevel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTierLevel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payoutDefinition = PayoutDefinition.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ladderRule = ExecutiveLadderRule.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.userType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.commissionType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TierLevel {
    return {
      payoutDefinition: isSet(object.payoutDefinition) ? PayoutDefinition.fromJSON(object.payoutDefinition) : undefined,
      ladderRule: isSet(object.ladderRule) ? ExecutiveLadderRule.fromJSON(object.ladderRule) : undefined,
      userType: isSet(object.userType) ? userTypeFromJSON(object.userType) : undefined,
      commissionType: isSet(object.commissionType) ? commissionTypeFromJSON(object.commissionType) : undefined,
    };
  },

  toJSON(message: TierLevel): unknown {
    const obj: any = {};
    message.payoutDefinition !== undefined &&
      (obj.payoutDefinition = message.payoutDefinition ? PayoutDefinition.toJSON(message.payoutDefinition) : undefined);
    message.ladderRule !== undefined &&
      (obj.ladderRule = message.ladderRule ? ExecutiveLadderRule.toJSON(message.ladderRule) : undefined);
    message.userType !== undefined &&
      (obj.userType = message.userType !== undefined ? userTypeToJSON(message.userType) : undefined);
    message.commissionType !== undefined && (obj.commissionType = message.commissionType !== undefined
      ? commissionTypeToJSON(message.commissionType)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<TierLevel>, I>>(base?: I): TierLevel {
    return TierLevel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TierLevel>, I>>(object: I): TierLevel {
    const message = createBaseTierLevel();
    message.payoutDefinition = (object.payoutDefinition !== undefined && object.payoutDefinition !== null)
      ? PayoutDefinition.fromPartial(object.payoutDefinition)
      : undefined;
    message.ladderRule = (object.ladderRule !== undefined && object.ladderRule !== null)
      ? ExecutiveLadderRule.fromPartial(object.ladderRule)
      : undefined;
    message.userType = object.userType ?? undefined;
    message.commissionType = object.commissionType ?? undefined;
    return message;
  },
};

function createBasePayoutDefinition(): PayoutDefinition {
  return { levelName: undefined, templateAgencyId: undefined, payerId: undefined };
}

export const PayoutDefinition = {
  encode(message: PayoutDefinition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.levelName !== undefined) {
      writer.uint32(10).string(message.levelName);
    }
    if (message.templateAgencyId !== undefined) {
      writer.uint32(18).string(message.templateAgencyId);
    }
    if (message.payerId !== undefined) {
      writer.uint32(26).string(message.payerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayoutDefinition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayoutDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.levelName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.templateAgencyId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.payerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PayoutDefinition {
    return {
      levelName: isSet(object.levelName) ? String(object.levelName) : undefined,
      templateAgencyId: isSet(object.templateAgencyId) ? String(object.templateAgencyId) : undefined,
      payerId: isSet(object.payerId) ? String(object.payerId) : undefined,
    };
  },

  toJSON(message: PayoutDefinition): unknown {
    const obj: any = {};
    message.levelName !== undefined && (obj.levelName = message.levelName);
    message.templateAgencyId !== undefined && (obj.templateAgencyId = message.templateAgencyId);
    message.payerId !== undefined && (obj.payerId = message.payerId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PayoutDefinition>, I>>(base?: I): PayoutDefinition {
    return PayoutDefinition.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PayoutDefinition>, I>>(object: I): PayoutDefinition {
    const message = createBasePayoutDefinition();
    message.levelName = object.levelName ?? undefined;
    message.templateAgencyId = object.templateAgencyId ?? undefined;
    message.payerId = object.payerId ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
